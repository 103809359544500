@charset "UTF-8";

:root {
  --background-color: #1d1d20;
  --background-color: #F5F5F5;
  --background-color: #EFEFEF;
  --background-color: #F7EFEF;
  --background-color: #DCEAF6;
  /*dark-mode*/
  --background-color: #1A1A1A;
  --background-color: #0D0D0D;
  --background-color_do: #0d0d0d20;
  --background-color_ot: #0d0d0de7;
  --base-color: rgba(255, 255, 255, 1);
  --base-color: #1DA1F2;
  --base-color: #0077B5;
  --base-color: #2196F3;
  --base-color: #3B5998;
  --base-color: #4A90E2;
  --base-color: #96aeffff;
  --base-color1: rgb(255, 255, 255);
  /* --base-color: #797ef9; */
  /* --base-color: #939aff; */
  --opacity: 0.7;
  --fond-text-color: #1d1d1d;
  --third-color: #f00;
  --let: substring(var(--base-color1), 4, -1)
}



@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

/* Sélectionner la barre de défilement */
::-webkit-scrollbar {
  width: 3px;
}

/* Personnaliser la poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background-color: var(--base-color);
  border-radius: 5px;
}

/* Personnaliser la couleur de fond de la barre de défilement */
::-webkit-scrollbar-track {
  background-color: var(--background-color);
}

.battery {
  display: flex;
  align-items: center;
  flex-direction: row;

  border: var(--border) var(--border-color);
  height: calc(100% - (var(--border) *2));
  width: calc(25% - (var(--border) *2));
}

.svgColor {
  height: 100%;
  width: 5%;
  display: flex;

  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  margin-left: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.svgColor {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}


body {
  font-weight: 300;
}


h1,
h2,
h3 {
  margin: 0px 0px 0px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 1px;
}

.opacity_05 {
  opacity: 0.5;
}


h1 {
  color: var(--base-color);
  font-size: 3rem;
}

h2 {
  color: var(--base-color);
  margin-top: 5px;
  font-size: 2.2rem;
}

h3 {
  font-size: 2rem;
  font-weight: 600;
  font-size: 1.8rem;
}

a {
  text-decoration: none;
  color: var(--base-color);
}

.main_message_salut {
  padding-left: 2rem;
  color: rgb(var(--secondary-color))
}

p {
  font-size: 1.2rem;
}

li {

  list-style: none;
  /* padding-left: 1rem; */

}

.big_heading {
  margin: 0px;
  margin-left: 0.2rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 6vmin;
  display: flex;
  height: 100%;
  width: 5%;
  align-items: center;
}

.chgt_color {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.svg_logo {
  max-width: 200px;
  height: 70%;

}


.projects-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

section {
  width: 90%;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: rgb(112, 76, 182);
}


html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  background-color: var(--background-color);
  color: var(--base-color);
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

.main {
  box-sizing: border-box;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.main-container {
  max-width: 100vw;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.main-container .main-container-inner {
  width: 100vw;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  position: relative;
}

.main-container .main-container-inner .inner-container {
  -webkit-user-select: none;
  user-select: none;
  z-index: 2;
  background: var(--background-color);
  border-radius: 5px;
  flex-wrap: wrap;
  overflow: hidden;
}

.main-container .main-container-inner .inner-container header .logo {
  z-index: 9;
  cursor: pointer;
  transition: all 50ms ease-in-out;
  width: 25%;
  padding-inline: 5%;
}

.svg_chgt_color {
  max-width: 200px;
  height: 65%;

}



.main-container .main-container-inner .inner-container {
  -webkit-user-select: none;
  user-select: none;
  z-index: 2;
  background: var(--background-color);
  border-radius: 5px;
  flex-wrap: wrap;
  overflow: hidden;
}

header ul {
  list-style: none;
  display: flex;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.inner-container header ul {
  list-style: none;
  display: flex;
}

.inner-container header ul a {
  margin-left: 30px;
  line-height: 1.5;
  display: flex;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

.main-message {
  position: relative;
  display: flex;
  min-height: 50vh;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: flex-end;
  padding-bottom: 15rem;

  width: 50%;
  padding-left: 5rem;


}

.contact_message_titre {
  position: relative;
  display: flex;
  /* min-height: 50vh; */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* height: 70%; */
  justify-content: flex-end;
  /* padding-bottom: 15rem; */
  padding-inline: 3rem;
}

.welcome-message {
  transform-origin: 0;
  opacity: 1;
  transition: all 0.2s linear;
  transform: scaleX(1);
}

.project_wrapper {
  font-size: 1rem;
  position: relative;
  width: 65%;


}

.project_wrapper div {
  border-top-left-radius: 7px;
  flex-direction: column;
  margin-left: 0;
  display: flex;
  position: relative;
  align-items: center;
}

.project_wrapper div a {
  opacity: 0.5;
  color: var(--base-color);
  margin-bottom: 15px;
  margin-left: 0;
  padding-bottom: 5px;
  line-height: 1.5;
  text-decoration: none;
}

.project_wrapper div a:hover {
  color: var(--base-color);

  animation: opacity_project_move 0.25s forwards;
  ;
}

.project_wrapper div a:not(:hover) {
  color: var(--base-color);
  animation: opacity_project_move_back 0.5s forwards;
}

.project_wrapper div a .span_ligne {
  display: inline-block;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
}



/* Définition de l'animation avec @keyframes */
@keyframes opacity_project_move {
  0% {
    opacity: 0.5;
    letter-spacing: 0px;
  }

  100% {
    opacity: 1;
    letter-spacing: 1px;
  }
}

@keyframes opacity_project_move_back {
  0% {
    opacity: 1;
    letter-spacing: 1px;
  }

  100% {
    opacity: 0.5;
    letter-spacing: 0px;
  }
}






.leopard_projet {
  display: flex;
  align-items: flex-end;
  height: 8rem;
  width: 100%;
}

.galerie_3d_projet {
  display: flex;
  align-items: flex-end;
  height: 8rem;
  width: 100%;
}

.github {
  padding-right: 0;
}

.nom_projet {
  width: 40%;
  margin-block-end: 0
}


.three_div {
  width: 60%;
  height: 100%;
}

.three_div img {
  width: auto;
  max-height: 100%;
}

.span_ligne {
  width: 100%;
  height: 100%;

}

/*nav bar line horizontal*/
.project_wrapper div a .span_ligne:after {
  content: "";
  height: 1px;
  width: 100%;
  background: linear-gradient(270deg, var(--base-color) 0%, rgb(var(--secondary-color)) 100%);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.pointSpan {
  content: "";
  width: 4px;
  height: 4px;
  right: 0;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease-out all;
  z-index: 5;
  background: rgb(var(--secondary-color));

}

.email {
  padding: 1.5rem;
}

.linkedIn:after,
.github:after,
.lifeLine:after,
.cv:after {
  content: "";
  height: 1px;
  width: 100%;
  background: linear-gradient(270deg, var(--base-color) 0%, rgb(var(--secondary-color)) 100%);
  opacity: 0;
  transition: all .3s cubic-bezier(0.22, 0.19, 0.73, 1.28);
  display: flex;
  bottom: -2px;
  left: 0;
  right: 0;
}

.lifeLine:hover::after {
  opacity: 1;
}

.linkedIn:hover::after {
  opacity: 1;
}

.github:hover::after {
  opacity: 1;
}

.cv:hover::after {
  opacity: 1;
}

/*Inversion de couleur*/
/**/
/**/
/*vertical line*/
.main-message .line_vertical {
  font-size: 0.9em;
  font-weight: 100;
  line-height: 1.6;
  display: block;
  position: relative;
}

.contact_message_titre .line_vertical {
  font-size: 0.9em;
  font-weight: 100;
  line-height: 1.6;
  display: block;
  position: relative;
}

/*vertical line*/
.line_vertical:before {
  content: " ";
  width: 2px;
  background: linear-gradient(0deg, var(--base-color) 0%, rgb(var(--secondary-color)) 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
}

footer {
  width: 100%;
  padding: 0px 0px 27px;
  font-size: 1rem;
  position: relative;
  height: 4vh;
}



/* competences */
.competences {
  padding: 0 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.competence_card {
  padding: 3rem 3rem 2.5rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  width: 100%;
  background: var(--fond-text-color);
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  border-top: 2px solid rgb(var(--secondary-color));
  overflow: hidden;

  position: relative;
  width: 20em;
  background-color: #292929;
  transition: all .2s ease-in-out;

}

.competence_card:hover {

  box-shadow: 0 5px 5px 5px rgba(var(--secondary-color), 1);
  transform: scale(1.005);
}


.competence_card h3 {
  color: rgb(var(--secondary-color));
}

.texte_competence {
  padding: 1rem 0;
}

.three {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  width: 100%;
  border-top: 2px solid rgb(var(--secondary-color));
  overflow: hidden;
}

section {
  display: flex;
  flex-direction: row;
}

.header-nav {
  position: fixed;
  flex-direction: row;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  height: 3rem;
  box-shadow: 0px -12px 17px 18px rgba(13, 13, 13, 1);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  background-image: radial-gradient(ellipse at top, rgba(13, 13, 13, 0.1), rgba(13, 13, 13, 0.8) 70%);
  top: 0
}




.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.ul_header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.ul_cv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.ul_header li {
  padding-left: 1rem;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-menu_nav {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  padding-block: 1%;
}

.main-menu_nav ul li a {
  display: flex;
  align-items: center;
}

.svg_cv {
  display: flex;
  align-items: center;
  /* color: -webkit-link; */
  cursor: pointer;
  /* text-decoration: underline; */
}


.main-menu_nav ul li a svg {
  padding-inline: 0.3rem;
}

.hello-section {
  margin-top: 7vh;
  height: 93vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.techno-section {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5rem;
}

.spacer {
  width: 50%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 95%;
  max-height: 85%;

}

.main-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  display: flex;
  min-height: 70vh;
  justify-content: center;
}



.espace-virtuel {
  width: 50%;
  height: 60%;
  padding-bottom: 15rem;

}

.virtuel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
}

.main-message a {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

/***/
.filled-button {
  position: relative;
  /* height: 100%; */
  width: 100%;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  margin-top: 20px;
  border: 2px solid rgb(var(--secondary-color));
}

/* Style de base pour le bouton */
.contact-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: var(--background-color);
  border: none;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  font-family: "Open Sans", sans-serif;
}

/* Style pour le remplissage */
.bef {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 0%;
  height: 100%;
  background-color: rgb(var(--secondary-color));
  transform-origin: top left;
  transform: skewX(-10deg);
  transition: all 0.3s ease-in-out;
}

/* Style pour l'animation au survol */
.contact-button:hover .bef {
  left: 0;
  width: 110%;
}

/* Style pour le remplissage */
/* Style pour le texte */
.contact-button .text {
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  color: rgb(var(--secondary-color))
}


/* Style pour la couleur de texte au survol */
.contact-button:hover .text {
  color: #000000 !important;
}

.filled-button {
  display: inline-block;
  inline-size: max-content;
}

.competence-text {
  font-size: 1.2rem;
}

/*page Contact*/
.contact_form {
  overflow: hidden;
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
  height: 100%;
  flex-direction: column;
}

.page_contact {
  overflow: hidden;
  position: static;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.section_contact {

  margin-top: 8vh;
  padding: 0rem 0rem;
  width: 100%;
  /* padding: 6rem; */
  justify-content: space-around;
  align-items: center;
}

.section_cv {
  margin-top: 8vh;
  padding: 0rem 0rem;
  align-items: center;
  flex-direction: column;
  /* max-width: 1480px; */

  /* padding: 0 3rem; */

}

.section_cv {
  height: 86vh;
}

.text_zone {
  width: 50%;
  max-width: 50rem;
}

#contact {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;


}

.input_field {
  background-color: var(--fond-text-color);
  border: none;
  width: 100%;
  border: 0;
  background: #2b2b2b;
  height: 50px;
  font-size: 16px;
  color: #8d8d8d;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.text_field {
  background-color: var(--fond-text-color);
  border: none;
  width: 100%;
  border: 0;
  background: #2b2b2b;
  height: 50px;
  font-size: 16px;
  color: #8d8d8d;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  width: 100%;
  border: 0;
  background: #2b2b2b;
  height: 50px;
  font-size: 16px;
  color: #8d8d8d;
  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 150px;

}

.contact_nom {
  width: 49%;
  padding-bottom: 2%;
  padding-left: 0;


}

.contact_email {
  width: 49%;

  padding-bottom: 2%;
  padding-left: 2%;

}

.contact_objet {
  width: 100%;
  padding-bottom: 2%;
  padding-left: 0;


}

.contact_message {
  width: 100%;
  padding-bottom: 2%;
  float: left;
  padding-left: 0;

}

.contact_ul {
  display: block;
  /* margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  margin-bottom: 0px; */

}

.half {
  float: left;
}

.map_contact {
  display: grid;
  /* height: 100vw; */

  z-index: 2;

}

.map_contact {

    box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 34);
    
}


.leaflet-control-zoom-in, .leaflet-control-zoom-out{
  background-color:#000 !important;
color:rgb(var(--secondary-color))!important;

}
.leaflet-control-zoom-out{
  margin-top: 3px;

}
.leaflet-right{
  font-size: 0;
}
.leaflet-control-zoom{
  background-color:#000 !important;
}
.leaflet-bar a {
  border: 2px solid rgb(var(--secondary-color))!important;
  border-radius: 0 !important;
}



.my-map {
  background-color: red;
}



.express {
  margin-top: 63px;
  display: block;
  font: 25px helvetica neue, open sans, sans-serif;
  font-weight: 100;
  color: #444;
}

.Svg_background {
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  
}
.svg_anim{
  position: absolute;
  
  width: 50px;
  height:50px;
  will-change: transform;
  transition: 2s cubic-bezier(0, 0, 0.42, 1.46);
  /* transition: none */
}

.slow{
  transition: 2s cubic-bezier(0, 0, 0.42, 1.46)
}

.Svg_background img {
  opacity: 0.14;
  /* opacity: 0.5; */

}

.evidenceSvg {
  opacity: 0.6 !important;



}

.toastify-color {
  color: var(--white-color);
}

.section_life_line {
  margin-top: 8vh;
  padding: 0rem 0rem;
  align-items: center;
  flex-direction: column;
  max-width: 1480px;

  /* padding: 0 3rem; */

}

.container_lifeline {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

}

.container_line {
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.line_group_lifeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9%;

}

.container_data_lifeline {
  width: 91%;
  margin-bottom: 5rem;
  display: flex;

}

.container_data_lifeline h3 {
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.16, 1, 0.3, 1), transform 0.9s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(-90px);
  transition-delay: 200ms;

}

.container_data_lifeline h2 {
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.16, 1, 0.3, 1), transform 0.9s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateX(-90px);
  transition-delay: 400ms;
}

.transform_paragraphe h2,
.transform_paragraphe h3 {
  opacity: 1;
  transform: translateX(0);

}


.container_text_lifeline {
  width: 70%;
}

.container_text_lifeline li {
  list-style-type: square;
}

.container_text_lifeline p {
  opacity: var(--opacity);
}

.container_text_lifeline span {
  color: rgb(var(--secondary-color));
}

.container_text_lifeline ul {
  opacity: var(--opacity);
  font-size: 1.2rem;
}

.line_begin_lifeline {
  background: linear-gradient(var(--base-color), rgb(var(--secondary-color)), black);
  width: 3px;
  height: 100%;
  transition: transform 1.7s cubic-bezier(0.16, 1, 0.3, 1);
  transform: scaleY(0);
  transform-origin: bottom;

}

.line_end_lifeline {
  width: 3px;
  height: 160px;
  background: linear-gradient(black, rgb(var(--secondary-color)), var(--base-color));
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  margin-bottom: 0.5rem;
  transition: transform 1.7s cubic-bezier(0.16, 1, 0.3, 1);
  transform: scaleY(0);

  transform-origin: bottom;


}

.transform_scale {
  transform: scaleY(1);

}

.svg_blur {
  background-color: rgb(var(--secondary-color));
  filter: blur(18px);

  height: 100%;
  width: 100%;
  display: flex;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
}

.svg_lifeline {

  opacity: 1;
  width: 24px;
  height: 24px;
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
}

.text-accent-primary {
  color: rgb(var(--secondary-color));
}

.titre_lifeline_container {

  color: var(--base-color);

}

.texte_lifeline_container {
  padding-top: 1rem;

}

.texte_lifeline_container p,
ul {
  padding-top: 1rem;
  transition: opacity 0.2s ease-in-out;

}

.texte_lifeline_container p:hover,
ul:hover {
  opacity: 1;
}

.container_images_lifeline {
  width: 100%;
}

.container_photos {

  width: 100%;
}

.info_simu_git {
  width: 7%;
  font-size: 1rem;
  text-align: center;
}

.pdf {
  height: 60vh;
  max-width: 85vw;
  min-width: 70vw;
}

.pdfViewer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  border: 2px solid rgb(var(--secondary-color));
  border-radius: 5px;
}


/*svg tombes*/
@keyframes fallingEffect {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100vh);
    visibility: hidden;
  }
}

.falling {
  position: absolute;
  animation: fallingEffect 1.2s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}




/* Styles pour les écrans jusqu'à 576px de large (mobile) */
@media screen and (max-width: 576px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .competence_card {
    width: 90%;

  }

  .competences {
    justify-content: center;
  }

  .three {
    width: calc(90% + 6rem);
    height: 25rem;
  }

  .main-message {

    width: 100%;
  }
 


  .hello-section {
    flex-direction: column;
    height: 200%;
  }


  .main-projects {
    width: 100%;
  }

  .espace-virtuel,
  .virtuel {
    padding-bottom: 0;
    width: 95vw;
    height: 95vw;
    align-items: center;
  }

  .techno-section {
    flex-direction: column
  }

  .spacer {
    width: 95%;
  }

  .project_wrapper {
    width: 95%;
  }

  html {
    font-size: 10px;
  }



  .hello-section {
    padding: 0 1rem;
  }

  main.projects-overview {
    padding-inline: 0rem;
  }

  .container_data_lifeline {
    flex-direction: column;
  }
  .info_simu_git{
    width: 25%;
  }

  .section_contact {
    flex-direction: column;
    padding: 0rem;
    height: auto;
  }

  .text_zone {
    width: 95%;
    max-width: 95%;
    margin-bottom: 5rem;
  }

  .map_contact {
    height: 95vw;
    width: 95%;

  }

  .leaflet-control .leaflet-container {
    height: 80px !important;
    width: 80px !important;
    ;
  }

  .container_text_lifeline {
    width: 90%;
  }

  .ul_header {
    justify-content: space-evenly;


  }

  nav ul {
    padding-left: 0;
  }

  .aligne_button {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .contact-button {
    font-size: 2.4rem;
  }
  .svg_anim{
    width: 20px;
    height:20px;
  }
}

/* Styles pour les écrans de 577px à 768px de large (tablettes en mode portrait) */
@media screen and (min-width: 577px) and (max-width: 768px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .competence_card {
    width: 90%;

  }

  .competences {
    justify-content: center;
  }

  .three {
    width: calc(90% + 6rem);
    height: 25rem;
  }

  .main-message {
    width: 100%;
  }

  .hello-section {
    flex-direction: column;
    height: auto;
  }


  .main-projects {
    height: 200%;
  }

  .espace-virtuel,
  .virtuel {
    padding-bottom: 0;
    width: 95vw;
    height: 95vw;
    align-items: center;
  }

  .techno-section {
    flex-direction: column
  }

  .spacer {
    width: 95%;
  }

  .project_wrapper {
    width: 95%;
  }

  html {
    font-size: 14px;
  }



  .hello-section {
    padding: 0 1.2rem;
  }

  main.projects-overview {
    padding-inline: 0rem;
  }

  .container_data_lifeline {
    flex-direction: column;
  }
  .info_simu_git{
    width: 25%;
  }

  .section_contact {
    flex-direction: column;
    padding: 0rem;
    height: 100%;
  }

  .text_zone {
    width: 95%;
    max-width: 95%;
    margin-bottom: 5rem;
  }

  .map_contact {
    height: 95vw;
    width: 95%;

  }

  .leaflet-control .leaflet-container {
    height: 80px !important;
    width: 80px !important;
    ;
  }

  .container_text_lifeline {
    width: 90%;
  }

  .ul_header {
    justify-content: space-evenly;
  }

  nav ul {
    padding-left: 0;
  }

  .aligne_button {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .contact-button {
    font-size: 2.4rem;
  }
  .svg_anim{
    width: 25px;
    height:25px;
  }
}

/* Styles pour les écrans de 769px à 992px de large (tablettes en mode paysage ou ordinateurs portables) */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
    max-width: 800px;
  }

  main.projects-overview {
    flex-direction: row;
    justify-content: space-between;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    box-sizing: border-box;
    min-height: calc(100% - 102px);
    height: calc(100% - 102px);
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    transform: translateZ(0);
  }

  .competence_card {
    width: 90%;

  }

  .competences {
    justify-content: center;
  }

  .three {
    width: calc(90% + 6rem);
    height: 25rem;
  }

  .main-message {
    width: 100%;
  }

  .hello-section {
    flex-direction: column;
    height: 200%;
  }


  .main-projects {
    width: 100%;
  }

  .espace-virtuel,
  .virtuel {
    padding-bottom: 0;
    width: 95vw;
    height: 95vw;
    align-items: center;
  }

  .techno-section {
    flex-direction: column
  }

  .spacer {
    width: 95%;
  }

  .project_wrapper {
    width: 95%;
  }

  html {
    font-size: 16px;
  }


  .hello-section {
    padding: 0 1.2rem;
  }

  main.projects-overview {
    padding-inline: 0.5rem;
  }

  .header-nav {
    max-width: 920px;
    height: 7%;
  }

  .container_data_lifeline {
    flex-direction: column;
  }
  .info_simu_git{
    width: 25%;
  }

  .section_contact {
    flex-direction: column;
    padding: 0rem;
    height: 100%;
  }

  .text_zone {
    width: 95%;
    max-width: 95%;
    margin-bottom: 5rem;
  }

  .map_contact {
    height: 95vw;
    width: 95%;
  }

  .leaflet-control .leaflet-container {
    height: 80px !important;
    width: 80px !important;
    ;
  }

  .container_text_lifeline {
    width: 90%;
  }

  .ul_header {
    justify-content: space-evenly;
  }

  .aligne_button {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .contact-button {
    font-size: 2.4rem;
  }
  .svg_anim{
    width: 30px;
    height:30px;
  }
}

/* Styles pour les écrans de 993px à 1200px de large (ordinateurs de bureau) */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .competence_card {
    width: 90%;

  }

  .competences {
    justify-content: center;
  }

  .three {
    width: calc(90% + 6rem);
    height: 25rem;
  }

  .main-message {
    width: 100%;
  }

  .hello-section {
    flex-direction: column;
    height: 200%;
  }


  .main-projects {
    width: 100%;
  }

  .espace-virtuel,
  .virtuel {
    padding-bottom: 0;
    width: 95vw;
    height: 95vw;
    align-items: center;
  }

  .techno-section {
    flex-direction: column
  }

  .spacer {
    width: 95%;
  }

  .project_wrapper {
    width: 95%;
  }

  html {
    font-size: 18px;
  }



  .hello-section {
    padding: 0 1.2rem;
  }

  main.projects-overview {
    padding-inline: 2rem;
  }

  .header-nav {
    max-width: 920px;
    height: 7%;
  }

  .container_data_lifeline {
    flex-direction: column;
  }
  .info_simu_git{
    width: 25%;
  }

  .section_contact {
    padding: 0rem;
    flex-direction: column;
    align-items: center;
    height: 100%;

  }

  .text_zone {
    width: 90%;
    display: flex;
    flex-direction: column;
    max-width: 95%;
    margin-bottom: 5rem;
  }

  .text_zone {
    width: 95%;
  }

  .map_contact {
    height: 95vw;
    width: 95%;

  }

  .ul_header {
    justify-content: space-evenly;
  }

  .aligne_button {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .contact-button {
    font-size: 2.4rem;
  }
}



/* Styles pour les écrans de 1201px à 1440px de large (moniteurs HD) */
@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .pdf {
    height: 85vh;

  }

  .main-container .main-container-inner {
    max-width: 1200px;
  }

  .main-container {
    align-items: center;
  }

  html {
    font-size: 14px;
  }

  .competence_card {
    width: 28%;

  }

  .three {
    width: calc(28% + 6rem)
  }


  .main-message {
    width: 100%;
  }

  .hello-section {
    flex-direction: column;
    height: 200%;
  }

  .espace-virtuel,
  .virtuel {
    width: 95vw;
    height: 95vw;
    padding-bottom: 0;
    align-items: center;
  }


  .techno-section {
    flex-direction: column
  }

  .spacer {
    width: 95%;
  }


  .competences {
    padding: 0 2rem;
    justify-content: space-evenly;
  }

  .header-nav {
    max-width: 1200px;
    height: 7%;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  html {
    font-size: 14px;
  }

  .section_contact {
    height: 86vh;
  }

  .map_contact {
    height: 100%;
    width: 50%;

  }


}

/* Styles pour les écrans de 1441px à 1920px de large (moniteurs Full HD) */
@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .pdf {
    height: 85vh;

  }

  .main-container .main-container-inner {
    max-width: 1550px;
  }

  .main-container {
    align-items: center;
  }



  .competence_card {
    width: 25%;

  }

  .three {
    width: calc(25% + 6rem)
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  .competences {
    padding: 0 2rem;
    justify-content: space-evenly;
  }

  .header-nav {
    max-width: 1600px;
    height: 7%;
  }

  .section_contact {
    height: 86vh;
  }

  .map_contact {
    height: 100%;
    width: 50%;

  }
}

/* Styles pour les écrans de 1921px à 3840px de large (moniteurs 4K) */
@media screen and (min-width: 1921px) and (max-width: 3840px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .pdf {
    height: 85vh;

  }

  .main-container .main-container-inner {
    max-width: 2800px;
  }

  .main-container {
    align-items: center;
  }

  .main-message {
    padding-left: 3rem;
  }

  .competence_card {
    width: 25%;

  }

  .three {
    width: calc(25% + 6rem)
  }

  .competences {
    padding: 0 2rem;
    justify-content: space-evenly;
  }

  .header-nav,
  .main-container .main-container-inner {
    max-width: 2400px;
  }

  h1 {
    font-size: 4.5rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 1.7rem;
  }

  html {
    font-size: 16px;
  }

  .header-nav {
    max-width: 2280px;
    height: 6%;
  }

  .section_contact {
    height: 86vh;
  }

  .map_contact {
    height: 100%;
    width: 50%;

  }
}

/* Styles pour les écrans de plus de 3840px de large (écrans 6K) */
@media screen and (min-width: 3841px) {
  .main-container .main-container-inner .inner-container footer {
    text-align: right;
  }

  .pdf {
    height: 85vh;

  }

  .main-container .main-container-inner {
    max-width: 2800px;
  }

  .main-container {
    align-items: center;
  }

  .main-message {
    padding-left: 3rem;
  }

  .competence_card {
    width: 25%;

  }

  .three {
    width: calc(25% + 6rem)
  }

  .competences {
    padding: 0 2rem;
    justify-content: space-evenly;
  }

  .header-nav,
  .main-container .main-container-inner {
    max-width: 2400px;
  }

  h1 {
    font-size: 4.5rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 1.7rem;
  }

  html {
    font-size: 16px;
  }

  .header-nav {
    max-width: 2280px;
    height: 5%;
  }

  .section_contact {
    height: 86vh;
  }

  .map_contact {
    height: 100%;
    width: 50%;

  }
}



/* // .leaflet-layer,
// .leaflet-control-zoom-in,
// .leaflet-control-zoom-out,
// .leaflet-control-attribution {
//   // filter: invert(0%) sepia(0%) saturate(5611%) hue-rotate(132deg) brightness(98%) contrast(105%);



  
  
  
//   filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(100%);
// } */